<template>
<div>
    <div class="d-flex justify-start align-center" style="height:60px;color: #d31145;background-color:#fff;padding: 0 20px 0 20px;">
      <v-icon color="#d31145" @click="$router.push('/master')">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">Tipe Penjualan</span>
    </div>
    
    <v-divider></v-divider>

    <div>
      <div v-if="sales_type_list.length > 0 || searching != ''" class="pa-4" style="background-color:#ffffff;height:60px">
        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-card flat v-if="sales_type_list.length < 1 && searching == '' && !process.run">
        <v-card-text style="padding: 20px 20px">
          <div style="font-weight:700;font-size:16pxline-height: 19px;padding-bottom: 5px">
            Anda belum menambahkan Tipe Penjualan
          </div>

          <div style="font-weight: normal;font-size: 14px;color:#ACACAC;line-height: 16px;padding-bottom: 5px">
            Contoh : Dine In, Take Away, Gofood, Grabfood,Shopeefood
          </div>

          <div style="padding-top:20px">
            <v-btn to="/master/sales-type/form" rounded depressed color="#d31145" elevation="0" class="white--text text-capitalize">
              <v-icon>mdi-plus</v-icon>
              Tambahkan
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card flat v-else-if="sales_type_list.length < 1 && searching != ''">
        <v-card-text
          class="d-flex align-center"
          style="height: calc(100vh - 200px); background: #f9f9f9; ">
            <v-img
              class="mx-auto"
              max-width="200"
              src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
            </v-img>
        </v-card-text>
      </v-card>

      <v-card flat v-else>
        <v-card-text 
          class="px-0 pb-0 py-0" 
          style="
            overflow: auto; 
            height: calc(100vh - 120px);
            background: #f9f9f9;
            padding-bottom: 65px !important;">

            <div v-if="sales_type_list.length < 1 && searching == '' && process.run">
              <v-skeleton-loader
                v-for="item in 15" :key="item"
                type="list-item-avatar-two-line">
              </v-skeleton-loader>
            </div>

          <v-list dense subheader two-line class="py-0">
            <template v-for="(item, i) in sales_type_list">
              <v-list-item :key="item.id" class="py-0">
                <v-list-item-avatar tile size="50" style="border-radius:5px !important">
                  <v-img
                    width="50"
                    height="50"
                    :src="item.image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 16px;" class="font-weight-bold">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    Mark Up + 
                    {{ item.markup_value | price }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div style="display:flex">
                    <v-btn icon :to="`/master/sales-type/form?id=${item.id}`">
                      <v-icon color="#d31145">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="getDelete(item)" icon>
                      <v-icon color="#d31145">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="i" class="mx-auto"></v-divider>
            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <v-btn v-if="sales_type_list.length > 0" class="float white--text text-capitalize" rounded color="#d31145" to="/master/sales-type/form">
      <v-icon color="#fff" class="my-float" left>mdi-plus</v-icon>
      Tambahkan
    </v-btn>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          Hapus Tipe Penjualan
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin menghapus Tipe Penjualan &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ detail.title }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-star align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="deleteData()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, Hapus Data
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- <v-dialog v-model="confirmation" persistent width="350">
      <v-card flat style="border-radius:15px">
        <v-card-title 
          class="subtitle-1 font-weight-bold white--text justify-center py-0" 
          style="background-color:#d31145;height:40px">
          
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
            Hapus Tipe Penjualan
          </div>
          <v-icon @click="confirmation = false" color="#fff">mdi-close-circle-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center py-4">
          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="detail.image_url">
          </v-img>
          <div class="mt-3 font-weight-bold">
            {{ detail.title }}
          </div>
          <p>
            Mark Up + {{ detail.markup_value | price}}
          </p>
        </v-card-text>
        <v-card-actions class="pb-4 px-3">
          <v-btn 
          @click="deleteData()" 
          rounded
          :loading="process.run"
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">
          <v-icon color="white" left>mdi-delete-outline</v-icon>
          <span class="subtitle-2">
            Hapus Data
          </span>
        </v-btn>
        <v-btn 
          @click="confirmation = false"
          rounded 
          :loading="process.run"
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">
          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>

  <!-- <div>
    <div class="d-flex justify-start align-center px-5" style="height:50px;background-color:#fff;">
      <v-icon color="#d31145" @click="$router.push('/master')">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700;color:#d31145">Tipe Penjualan</span>
    </div>
    <v-divider></v-divider>
    <div>
      <v-card flat v-if="sales_type_list.length < 1">
        <v-card-text style="padding: 20px 20px">
          <div style="font-weight:700;font-size:16pxline-height: 19px;padding-bottom: 5px">
            Anda belum menambahkan tipe penjualan
          </div>
          <div style="font-weight: normal;font-size: 14px;color:#ACACAC;line-height: 16px;padding-bottom: 5px">
            Contoh : Dine In, Take Away, Gofood, Grabfood,Shopeefood
          </div>

          <div style="padding-top:20px">
            <v-btn depressed color="#d31145" to="/master/sales-type/create" elevation="0" class="white--text text-capitalize">
              <v-icon>mdi-plus</v-icon>
              Tambahkan
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card flat v-else>
        <v-card-text class="px-0 pb-0" style="overflow:auto;height:calc(100vh - 50px);">

          <v-list subheader two-line>
            <template v-for="(item,i) in sales_type_list">
              <v-list-item :key="i">
                <v-list-item-avatar tile size="50" style="border-radius:5px !important">
                  <v-img
                    width="50"
                    height="50"
                    :src="item.image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="#d31145"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 16px;">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">Mark Up + {{ item.markup_value | price }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <div style="display:flex">
                    <v-btn icon :to="`/master/sales-type/form?id=${item.id}`">
                      <v-icon color="#d31145">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="getDelete(item)" icon>
                      <v-icon color="#d31145">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="i" class="mx-auto"></v-divider>
            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    
    <v-btn class="float white--text" rounded color="#d31145" to="/master/sales-type/form">
      <v-icon color="#fff" class="my-float" left>mdi-plus</v-icon>
      Tambahkan
    </v-btn>

    <v-dialog v-model="confirmation" persistent width="300">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 font-weight-bold white--text justify-center" style="background-color:#d31145">
          Hapus Tipe Penjualan
        </v-card-title>
        <v-card-text class="text-center pb-0">
          <v-img
            contain
            width="100"
            height="100"
            class="mx-auto mt-3"
            :src="detail.image_url">
          </v-img>
          <p class="mt-3">
            {{ detail.title }}
          </p>
          <p class="mt-3">
            Mark Up + {{ detail.markup_value | price}}
          </p>
        </v-card-text>
        <v-card-actions class="pb-8 px-3">
          <v-btn 
          @click="deleteData()" 
          rounded
          :loading="process.run"
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">
          <v-icon color="white" left>mdi-delete-outline</v-icon>
          <span class="subtitle-2">
            Hapus Data
          </span>
        </v-btn>
        <v-btn 
          @click="confirmation = false"
          rounded 
          :loading="process.run"
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">
          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div> -->
</template>

<script>
export default {
  data: () => ({
    sales_type_list: [],
    pagination: {},
    searching: "",
    confirmation: false,
    detail: {},
    process: {
      run: false
    }
  }),
  components: {

  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;

      this.initialize();
    }, 500),
  },
  computed: {

  },
  created() {

  },
  mounted(){
    this.initialize()
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > window.innerHeight-100
    },
    goBack(){
      this.$router.push(`/master`)
    },
    async initialize(){
      this.process.run = true
      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 1000,
        page: 1
      }
      let res = await this.$get(`pos/sales-type`, params)

      if (res.status == 200) {
        this.process.run = false
        this.sales_type_list = res.results.data
        this.pagination = res.results.pagination
      }

      this.process.run = false
    },
    gotoForm(item){
      this.detail = item
      this.$router.push(`/master/sales-type/form`)
    },
    getDelete(item){
      // console.log(item);
      this.confirmation = true
      this.detail = item
    },
    async deleteData(){
      this.confirmation = false
      this.$store.state.overlay.state = true
      this.process.run = true
      let data = {
        id: this.detail.id,
      }
      let res = await this.$post(`pos/sales-type/delete`, data)

      if (res.status == 200) {
        this.process.run = false
        this.initialize()
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        localStorage.setItem('user', JSON.stringify(res.results.data))
      }

      this.process.run = false
      this.$store.state.overlay.state = false
    },
  },
}
</script>